import React, { useState } from "react";
import axios from "axios";
import {
  StyleSheet,
  ImageBackground,
  Image,
  ScrollView,
  Text,
  StatusBar,
  View,
} from "react-native";
import {
  Input,
  Box,
  HStack,
  Center,
  VStack,
  Button,
  Link,
  Heading,
  Divider,
  FlatList,
} from "native-base";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 40,
    backgroundColor: "#ecf0f1",
  },
});

import { useMyContext  } from '../App';

export const CrearCategoria = ({ navigation }) => {

  const { globalVariable, setGlobalVariable } = useMyContext();

  const [formData, setFormData] = useState({
    id_categoria: "",
    nombre: "",
  });

  const handleInputChange = (name, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = () => {



    axios
      .post(
        "https://wyp7w1g0w1.execute-api.us-east-2.amazonaws.com/prod/categorias",
        formData
      )
      .then((response) => {
        console.log("Response:", response.data);
        setFormData({
          id_categoria: "",
          nombre: "",
        });
        navigation.navigate("Home");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "black",
        width: "100%",
        height: "100vh",
      }}
    >
      <Box
        style={{
          borderStyle: "solid",
          borderWidth: 1,
          width: "1024px",
          height: "600px",
          backgroundColor: "white",
        }}
      >
        <HStack>
          <Center h="600" w="664px" bg="white">
            <ImageBackground
              source={require("../assets/images/back1.png")}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "665px",
                height: "600px",
                padding: "20px",
              }}
            >
              <Box
                width="100%"
                height="100%"
                style={{
                  backgroundColor: "white",
                  padding: "20px",
                  borderRadius: "10px",
                  boxShadow: "0 0 5px 2px rgba(0, 0, 0, 0.5)",
                  opacity: "0.95",
                }}
              >
                <VStack space={4}>
                  <Center alignItems="left">
                    <Heading size="lg">Crear Categoria</Heading>
                    <Divider></Divider>
                  </Center>

                  <Center alignItems="center">
                    <Input mx="10" placeholder="Nombre" w="100%" 
                    onChangeText={(text) => handleInputChange("id_categoria", text)}/>
                  </Center>

                  <Center alignItems="center">
                    <Button
                      width={"200px"}
                      mx="10"
                      size="sm"
                      onPress={handleSubmit}
                    >
                      CREAR CATEGORIA
                    </Button>
                  </Center>
                </VStack>
              </Box>
            </ImageBackground>
          </Center>

          <Center
            h="600"
            w="360px"
            bg="blueGray.900"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <VStack space={4} alignItems="center">
              <Center>
                <Image
                  source={require("../assets/images/logo1.png")}
                  style={{
                    width: "104px",
                    height: "138px",
                  }}
                />
              </Center>

              <Center alignItems="center">
                <Button
                  width={"200px"}
                  mx="10"
                  size="sm"
                  onPress={() => navigation.navigate("CrearGrilla")}
                >
                  CREAR GRILLA
                </Button>
              </Center>

              <Center alignItems="center">
                <Button
                  width={"200px"}
                  mx="10"
                  size="sm"
                  onPress={() => navigation.navigate("CrearCategoria")}
                >
                  CREAR CATEGORIA
                </Button>
              </Center>

              <Center alignItems="center">
                <Button
                  width={"200px"}
                  mx="10"
                  size="sm"
                  onPress={() => navigation.navigate("CrearPictograma")}
                >
                  CREAR PICTOGRAMA
                </Button>
              </Center>

              <Center alignItems="center">
                <Button
                  width={"200px"}
                  mx="10"
                  size="sm"
                  onPress={() => navigation.navigate("Home")}
                >
                  MIS GRILLAS
                </Button>
              </Center>

              <Center alignItems="center">
                <Link
                  style={{ cursor: "pointer" }}
                  _text={{ color: "white" }}
                  onPress={() => navigation.navigate("Ingresar")}
                >
                  Cerrar sesión
                </Link>
              </Center>
            </VStack>
          </Center>
        </HStack>
      </Box>
    </Box>
  );
};
