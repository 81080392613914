import React, { createContext, useContext, useState } from 'react';
import { NativeBaseProvider, Box } from "native-base";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";

import { Registrarse } from "./components/registrarse.component";
import { Ingresar } from "./components/ingresar.component";
import { Home } from "./components/home.component";
import { CrearGrilla } from "./components/crear.grilla.component";
import { CrearPictograma } from "./components/crear.pictograma.component";
import { CrearCategoria } from "./components/crear.categoria.component";
import { CargarGrilla } from "./components/cargar.grilla.component";

const MyContext = createContext();

export const MyProvider = ({ children }) => {
  const [globalVariable, setGlobalVariable] = useState({});

  return (
    <MyContext.Provider value={{ globalVariable, setGlobalVariable }}>
      {children}
    </MyContext.Provider>
  );
};

export function useMyContext() {
  return useContext(MyContext);
}

const Stack = createStackNavigator();

export default function App() {
  return (
    <MyProvider>
      <NativeBaseProvider>
        <NavigationContainer>
          <Stack.Navigator initialRouteName="Ingresar" screenOptions={{headerShown: false}}>
            <Stack.Screen name="Registrarse" component={Registrarse} />
            <Stack.Screen name="Ingresar" component={Ingresar} />
            <Stack.Screen name="Home" component={Home} />
            <Stack.Screen name="CrearGrilla" component={CrearGrilla} />
            <Stack.Screen name="CargarGrilla" component={CargarGrilla} />
            <Stack.Screen name="CrearPictograma" component={CrearPictograma} />
            <Stack.Screen name="CrearCategoria" component={CrearCategoria} />
          </Stack.Navigator>
        </NavigationContainer>
      </NativeBaseProvider>
    </MyProvider>
  );
}
