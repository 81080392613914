import React, { useState } from 'react';
import axios from "axios";
import { ImageBackground, Image } from "react-native";
import { Input, Box, HStack, Center, VStack, Button, Link } from "native-base";

import { useMyContext  } from '../App';

export const Ingresar = ({ navigation }) => {

  const { globalVariable, setGlobalVariable } = useMyContext();

  const [formData, setFormData] = useState({
    email: "",
    contrasena: "",
  });

  const handleInputChange = (name, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    axios
      .get(
        `https://wyp7w1g0w1.execute-api.us-east-2.amazonaws.com/prod/usuarios/${formData.email}`,
      )
      .then((response) => {
        console.log("Response:", response.data);
        if (response.data.result.contrasena === formData.contrasena) {
          setFormData({
            email: "",
            contrasena: "",
          });
          setGlobalVariable(response.data.result)
          navigation.navigate("Home")
        } else {
          setGlobalVariable({})
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "black",
        width: "100%",
        height: "100vh",
      }}
    >
      <Box
        style={{
          borderStyle: "solid",
          borderWidth: 1,
          width: "1024px",
          height: "600px",
          backgroundColor: "white",
        }}
      >
        <HStack>
          <Center h="600" w="664px" bg="white">
            <ImageBackground
              source={require("../assets/images/back1.png")}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "1024px",
                height: "600px",
              }}
            ></ImageBackground>
          </Center>

          <Center
            h="600"
            w="360px"
            bg="blueGray.900"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <VStack space={4} alignItems="center">

              <Center>
                <Image
                  source={require("../assets/images/logo1.png")}
                  style={{
                    width: "104px",
                    height: "138px",
                  }}
                />
              </Center>

              <Center alignItems="center">
                <Input mx="10" placeholder="Correo Electronico" w="100%" 
                onChangeText={(text) => handleInputChange("email", text)}
                value={formData.email}
                style={{ color: "white" }} />
              </Center>

              <Center alignItems="center">
                <Input mx="10" placeholder="Contraseña" w="100%"
                onChangeText={(text) => handleInputChange("contrasena", text)}
                value={formData.contrasena}
                style={{ color: "white" }} 
                type="password"/>
              </Center>

              <Center alignItems="center">
                <Button
                  width={"200px"}
                  mx="10"
                  size="sm"
                  onPress={handleSubmit}
                >
                  INGRESAR
                </Button>
              </Center>

              <Center alignItems="center">
                <Link
                  style={{ cursor: "pointer" }}
                  _text={{ color: "white" }}
                  onPress={() => navigation.navigate("Registrarse")}
                >
                  Registrarse
                </Link>
              </Center>

            </VStack>
          </Center>
        </HStack>
      </Box>
    </Box>
  );
};
