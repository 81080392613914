import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  StyleSheet,
  ImageBackground,
  Image,
  ScrollView,
  Text,
  StatusBar,
  View,
} from "react-native";
import {
  Input,
  Box,
  HStack,
  Center,
  VStack,
  Button,
  Link,
  Heading,
  Divider,
  FlatList,
  Select,
} from "native-base";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 40,
    backgroundColor: "#ecf0f1",
  },
});

export const CargarGrilla = ({ route, navigation }) => {
  // pictogramas

  const [dataPictogramas, setDataPictogramas] = useState([]);
  const [loadingPictogramas, setLoadingPictogramas] = useState(true);

  // resto

  const { id_grilla } = route.params;

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchAll = async () => {
    const fetchDataPictogramas = async () => {
      try {
        const response = await axios.get(
          "https://wyp7w1g0w1.execute-api.us-east-2.amazonaws.com/prod/pictogramas"
        );
        console.log("Response:", response.data.result);
        setDataPictogramas(response.data.result);
      } catch (error) {
        console.error("Error fetching data: ", error);
        // Optionally set an error state here
      } finally {
        setLoadingPictogramas(false);
      }
    };

    fetchDataPictogramas();

    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://wyp7w1g0w1.execute-api.us-east-2.amazonaws.com/prod/grillas/${id_grilla}`
        );
        console.log("Response:", response.data.result);
        setData(response.data.result);
      } catch (error) {
        console.error("Error fetching data: ", error);
        // Optionally set an error state here
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  };

  useEffect(() => {
    fetchAll();
  }, []);

  // añadir pictograma

  const [formData, setFormData] = useState({
    id_pictograma: "",
  });

  const handleInputChange = (name, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    data.pictogramas.push(formData.id_pictograma);

    console.log("data:", data);

    axios
      .post(
        "https://wyp7w1g0w1.execute-api.us-east-2.amazonaws.com/prod/grillas",
        data
      )
      .then((response) => {
        console.log("Response:", response.data);
        setFormData({
          id_grilla: "",
        });
        navigation.navigate("Home");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "black",
        width: "100%",
        height: "100vh",
      }}
    >
      <Box
        style={{
          borderStyle: "solid",
          borderWidth: 1,
          width: "1024px",
          height: "600px",
          backgroundColor: "white",
        }}
      >
        <HStack>
          <Center h="600" w="664px" bg="white">
            <ImageBackground
              source={require("../assets/images/back1.png")}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "665px",
                height: "600px",
                padding: "20px",
              }}
            >
              <Box
                width="100%"
                height="100%"
                style={{
                  backgroundColor: "white",
                  padding: "20px",
                  borderRadius: "10px",
                  boxShadow: "0 0 5px 2px rgba(0, 0, 0, 0.5)",
                  opacity: "0.95",
                }}
              >
                <VStack space={4}>
                  <Center alignItems="left">
                    <Heading size="lg">Grilla: {id_grilla}</Heading>
                    <Divider></Divider>
                  </Center>

                  <Center alignItems="center">
                    <FlatList
                      data={data.pictogramas}
                      keyExtractor={(item, index) => {
                        return item;
                      }}
                      numColumns={3}
                      renderItem={({ item, index }) => (
                        <View>
                          <Image
                            style={{
                              width: "104px",
                              height: "88px",
                            }}
                            source={item}
                          />
                        </View>
                      )}
                    />
                  </Center>

                  <Center alignItems="center">
                    <Select
                      w="100%"
                      minWidth="200"
                      accessibilityLabel="Pictograma"
                      placeholder="Pictograma"
                      onValueChange={(text) =>
                        handleInputChange("id_pictograma", text)
                      }
                    >
                      {dataPictogramas.map((item, index) => (
                        <Select.Item
                          key={item.id_pictograma}
                          label={`[${item.id_categoria}] ${item.id_pictograma}`}
                          value={item.imagen}
                        />
                      ))}
                    </Select>
                  </Center>

                  <Center alignItems="center">
                    <Button
                      width={"200px"}
                      mx="10"
                      size="sm"
                      onPress={() => handleSubmit()}
                    >
                      Añadir Pictograma
                    </Button>
                  </Center>
                </VStack>
              </Box>
            </ImageBackground>
          </Center>

          <Center
            h="600"
            w="360px"
            bg="blueGray.900"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <VStack space={4} alignItems="center">
              <Center>
                <Image
                  onClick={() => fetchAll()}
                  source={require("../assets/images/logo1.png")}
                  style={{
                    width: "104px",
                    height: "138px",
                  }}
                />
              </Center>

              <Center alignItems="center">
                <Button
                  width={"200px"}
                  mx="10"
                  size="sm"
                  onPress={() => navigation.navigate("CrearGrilla")}
                >
                  CREAR GRILLA
                </Button>
              </Center>

              <Center alignItems="center">
                <Button
                  width={"200px"}
                  mx="10"
                  size="sm"
                  onPress={() => navigation.navigate("CrearCategoria")}
                >
                  CREAR CATEGORIA
                </Button>
              </Center>

              <Center alignItems="center">
                <Button
                  width={"200px"}
                  mx="10"
                  size="sm"
                  onPress={() => navigation.navigate("CrearPictograma")}
                >
                  CREAR PICTOGRAMA
                </Button>
              </Center>

              <Center alignItems="center">
                <Button
                  width={"200px"}
                  mx="10"
                  size="sm"
                  onPress={() => navigation.navigate("Home")}
                >
                  MIS GRILLAS
                </Button>
              </Center>

              <Center alignItems="center">
                <Link
                  style={{ cursor: "pointer" }}
                  _text={{ color: "white" }}
                  onPress={() => navigation.navigate("Ingresar")}
                >
                  Cerrar sesión
                </Link>
              </Center>
            </VStack>
          </Center>
        </HStack>
      </Box>
    </Box>
  );
};
