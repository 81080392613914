import React, { useEffect, useState } from "react";
import { View, Text, ActivityIndicator } from "react-native";
import axios from "axios";
import { ImageBackground, Image } from "react-native";
import {
  Input,
  Box,
  HStack,
  Center,
  VStack,
  Button,
  Link,
  Heading,
  Divider,
} from "native-base";

import { useMyContext } from "../App";

export const Home = ({ navigation }) => {
  const { globalVariable, setGlobalVariable } = useMyContext();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://wyp7w1g0w1.execute-api.us-east-2.amazonaws.com/prod/grillas"
      );
      console.log("Response:", response.data.result);
      setData(response.data.result);
    } catch (error) {
      console.error("Error fetching data: ", error);
      // Optionally set an error state here
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "black",
        width: "100%",
        height: "100vh",
      }}
    >
      <Box
        style={{
          borderStyle: "solid",
          borderWidth: 1,
          width: "1024px",
          height: "600px",
          backgroundColor: "white",
        }}
      >
        <HStack>
          <Center h="600" w="664px" bg="white">
            <ImageBackground
              source={require("../assets/images/back1.png")}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "665px",
                height: "600px",
                padding: "20px",
              }}
            >
              <Box
                width="100%"
                height="100%"
                style={{
                  backgroundColor: "white",
                  padding: "20px",
                  borderRadius: "10px",
                  boxShadow: "0 0 5px 2px rgba(0, 0, 0, 0.5)",
                  opacity: "0.95",
                }}
              >
                <VStack space={4}>
                  <Center alignItems="left">
                    <Heading size="lg">Mis Grillas</Heading>
                    <Divider></Divider>
                  </Center>

                  {data.map((item, index) =>
                    globalVariable.perfil === "admin" ? (
                      <Center key={item.id_grilla}>
                        <Button
                          width={"100%"}
                          size="md"
                          onPress={() =>
                            navigation.navigate("CargarGrilla", {
                              id_grilla: item.id_grilla,
                            })
                          }
                        >
                          {item.id_grilla}
                        </Button>
                      </Center>
                    ) : (
                      item.email === globalVariable.email && (
                        <Center key={item.id_grilla}>
                          <Button
                            width={"100%"}
                            size="md"
                            onPress={() =>
                              navigation.navigate("CargarGrilla", {
                                id_grilla: item.id_grilla,
                              })
                            }
                          >
                            {item.id_grilla}
                          </Button>
                        </Center>
                      )
                    )
                  )}
                </VStack>
              </Box>
            </ImageBackground>
          </Center>

          <Center
            h="600"
            w="360px"
            bg="blueGray.900"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <VStack space={4} alignItems="center">
              <Center>
                <Image
                  onClick={() => fetchData()}
                  source={require("../assets/images/logo1.png")}
                  style={{
                    width: "104px",
                    height: "138px",
                  }}
                />
              </Center>

              <Center alignItems="center">
                <Button
                  width={"200px"}
                  mx="10"
                  size="sm"
                  onPress={() => navigation.navigate("CrearGrilla")}
                >
                  CREAR GRILLA
                </Button>
              </Center>

              <Center alignItems="center">
                <Button
                  width={"200px"}
                  mx="10"
                  size="sm"
                  onPress={() => navigation.navigate("CrearCategoria")}
                >
                  CREAR CATEGORIA
                </Button>
              </Center>

              <Center alignItems="center">
                <Button
                  width={"200px"}
                  mx="10"
                  size="sm"
                  onPress={() => navigation.navigate("CrearPictograma")}
                >
                  CREAR PICTOGRAMA
                </Button>
              </Center>

              <Center alignItems="center">
                <Button
                  width={"200px"}
                  mx="10"
                  size="sm"
                  onPress={() => navigation.navigate("Home")}
                >
                  MIS GRILLAS
                </Button>
              </Center>

              <Center alignItems="center">
                <Link
                  style={{ cursor: "pointer" }}
                  _text={{ color: "white" }}
                  onPress={() => navigation.navigate("Ingresar")}
                >
                  Cerrar sesión
                </Link>
              </Center>
            </VStack>
          </Center>
        </HStack>
      </Box>
    </Box>
  );
};
